* {
    margin:0;
    padding: 0px;
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
}
body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}
.App{
    .App-header{
        .container-bar{
            div.icon-social{
                a,button{
                    span.tittle{
                        position: fixed;
                        background: #1c1c1c;
                        padding: 6px;
                        border-radius: 3px;
                        font-size: 15px;
                        margin-top: -4px;
                        transition: all 500ms ease;
                        opacity: 0;
                        visibility: hidden;
                    }
                    span.tittle:after{
                        position: absolute;
                        content: '';
                        border-left: 7px solid transparent;
                        border-right: 6px solid #1c1c1c;
                        border-top: 7px solid transparent;
                        border-bottom: 7px solid transparent;
                        right: 100%;
                        top:8px;
                    }
                    display: block;
                    padding: 15px;
                    text-decoration: none;
                    font-size: 17px;
                    position: relative;
                    transition: all 500ms ease;
                    cursor: pointer;
                    border-width: 0px;
                }
                a:hover,button:hover{
                    span.tittle{
                        opacity: 1;
                        visibility: visible;
                        margin-left: 30px;
                    }
                    background: #1c1c1c;
                }
                a.icon-home,button.icon-home{
                    color: white;
                    background: #e61212;
                    border-radius: 0px 5px 0px 0px;
                    font-size: 24px;
                }
                a.icon-about,button.icon-about{
                    color: white;
                    background: #1e10da;
                    border-radius: 0px 0px 0px 0px;
                    font-size: 24px;
                }
                a.icon-portafolio,button.icon-portafolio{
                    color: white;
                    background: #f33f09;
                    border-radius: 0px 0px 0px 0px;
                    font-size: 24px;
                }
                a.icon-language,button.icon-language{
                    color: white;
                    background: #000000;
                    border-radius: 0px 0px 0px 0px;
                    font-size: 24px;
                }
                a.icon-experience,button.icon-experience{
                    color: white;
                    background: #0996af;
                    border-radius: 0px 0px 0px 0px;
                    font-size: 24px;
                }
                a.icon-contacto,button.icon-contacto{
                    color: white;
                    background: #0ad814;
                    border-radius: 0px 0px 5px 0px;
                    font-size: 24px;
                }
                button.icon-home,button.icon-portafolio,button.icon-language,button.icon-experience,button.icon-about{
                    font-size: 25px !important;
                }
                
                button.icon-contacto{
                    font-size: 25px !important;
                }
                transition: all 0.4s;
                transform: translateX(0%);
                box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
            }
            input#btn-social{
                display: none;
            }
            input#btn-social:checked ~.bi-arrow-right-circle-fill{
                transform: rotate(0deg);
            }
            input#btn-social:checked ~.icon-social{
                transform: translateX(-100%);
            }
            label.bi-arrow-right-circle-fill{
                cursor: pointer;
                transition: all 0.4s;
                font-size: 25px;
                margin-bottom: 5px;
                display: inline-block;
                transform: rotate(180deg);
                color: rgb(0, 0, 0);
                margin-left: 5px;
            }
            width: 100%;
            max-width: 55px;
            position: fixed !important;
            left:0;
            top:25%;
            z-index: 9999;
        }
    }
    .App-body{
        div#home-section {
            div.d-flex{
                div.row{
                    ul{
                        li{
                            list-style: none;
                            color: #1e10da;
                            display: inline-block;
                            padding: 15px;
                            text-decoration: none;
                            font-weight: 600;
                            font-size: x-large;
                            text-shadow: 2px 2px 4px rgba(0, 0.8, 0, 0.3);
                            font-family: sans-serif;
                        }
                        margin: 0px;
                    }
                    justify-content: center;
                }
                height: 100%;
                text-align: center;
            }
            width: 100%;
            background: white;
        }
        div#about-section{
            .container-fluid{
                .card{
                    .card-header{
                        background: #1e10da;
                    }
                    .card-body{
                        .about{
                            .component{
                                .content{
                                    img {
                                        height: auto;
                                        width: 600px;
                                        max-width: 100%;
                                    }
                                    .text{
                                        h1 {
                                            color: #1e10da;
                                            font-size: 65px;
                                            margin-bottom: 20px;
                                            text-transform: capitalize;
                                        }
                                        p {
                                            color: #000000;
                                            font-size: 18px;
                                            line-height: 28px;
                                            letter-spacing: 1px;
                                            margin-bottom: 10px;
                                        }
                                        .data-personal{
                                            color: #000000;
                                            font-size: 18px;
                                            line-height: 28px;
                                            letter-spacing: 1px;
                                            margin-bottom: 10px;
                                        }
                                        width: 550px;
                                        max-width: 100%;
                                        padding: 0 10px;
                                        text-align: -webkit-left;
                                    }
                                    width: 95vw !important;
                                    max-width: 95%;
                                    margin: 0 auto;
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-items: center;
                                    justify-content: center;
                                }
                                width: 100%;
                                
                            }
                            width: 100%;
                            background-color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        @media screen and (max-width: 450px){
                            .text h1{
                                font-size: 40px;
                                margin-bottom: 25px;
                            }
                        }
                        padding: 0px !important;
                    }
                }
            }
            
        }
        div#portafolio-section{
            div.container-fluid{
                div.card{
                    div.card-header{
                        background: #f33f09;
                    }
                    div.card-body{
                        padding-right: 16px;
                        div.card{
                            .card-header{
                                background-color: #3469e0;
                            }
                            .card-body{
                                .row{
                                    div{
                                        .card{
                                            div{
                                                iframe{
                                                    width: 100%;
                                                    height: 500px;
                                                }
                                            }
                                            div.card-header{
                                                font-style: normal;
                                                font-family: monospace;
                                                font-size: 15px;
                                            }
                                            div.card-body{
                                                .card-text{
                                                    font-size: 13px; 
                                                }
                                                padding-bottom: 0px;
                                            }
                                            div.card-footer{
                                                a{
                                                    margin: 1px;
                                                }
                                                border: none;
                                                background: none;
                                            }
                                            padding-bottom: 0px;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    border: thick;
                    padding-bottom: 15px;
                }
                padding: 0px !important;
            }
        }
        div#experience-section{
            div.container-fluid{
                div.card{
                    div.card-header{
                        background: #0996af;
                    }
                    div.card-body{
                        .row{
                            div{
                                .card{
                                    .card-image{
                                        img.card-img-top{
                                            object-fit: contain;
                                            height: 150px;
                                            width: 100%;
                                        }
                                    }
                                    .card-footer{
                                        padding: unset;
                                        color: unset;
                                        background-color: unset;
                                        border-top: unset;
                                    }
                                    height: 100%;
                                }
                                .modal{
                                    .modal-dialog{
                                        .modal-content{
                                            .modal-body{
                                                img.img-thumbnail{
                                                    transition: transform 0.3s ease;
                                                }
                                                img.img-thumbnail:hover{
                                                    transform: scale(1.3);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .experiencie-bottom{
                                margin-bottom: 10px;
                            }
                        }
                    }
                    border: thick;
                }
                padding: 0px !important;
            }
        }
        div#language-section{
            .container-fluid{
                .card{
                    .card-header{
                        background-color: #000000;
                    }
                    .card-body{
                        div.container{
                            div.row{
                                div.flip-card{
                                    div.flip-card-inner{
                                        div.flip-card-front{
                                            div.card{
                                                img{
                                                    height: 6rem;
                                                    width: 100px;
                                                }
                                                transition: transform 0.6s;
                                                transform-style: preserve-3d;
                                                align-items: center;
                                            }
                                            div.card:hover{
                                                transform: rotateY(180deg);
                                            }
                                            display: flex;//aca
                                            justify-content: center;
                                            align-items: center;
                                            z-index: 2;
                                        }
                                        
                                        div.flip-card-back{
                                            .circular-progress {
                                                .progress-value {
                                                    position: relative;
                                                    font-size: 20px;
                                                    font-weight: 600;
                                                    color: #7d2ae8;
                                                }
                                                position: relative;
                                                height: 90px;
                                                width: 90px;
                                                border-radius: 50%;
                                                background: conic-gradient(#7d2ae8 3.6deg, #ededed 4deg);
                                                display: flex;/**/
                                                align-items: center;/**/
                                                justify-content: center;/**/
                                            }
                                            .circular-progress::before{
                                                content: "";
                                                position: absolute;
                                                height: 65px;
                                                width: 65px;
                                                border-radius: 50%;
                                                background-color: #fff;
                                            }
                                            .text{
                                                font-size: 15px;
                                                font-weight: 500;
                                                color: #606060;
                                            }
                                            //height: 20rem;
                                            transform: rotateY(180deg);
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;
                                            padding: 20px;
                                            z-index: 3;
                                        }
                                        position: relative;
                                        width: 150px;
                                        height: 100%;
                                        text-align: center;
                                        transition: transform 0.5s ease;
                                        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);//box
                                        transform-style: preserve-3d;
                                        text-decoration: none;
                                        color:black;
                                    }
                                    .flip-card-front, .flip-card-back {
                                        width: 100%;
                                        height: 100%;
                                        -webkit-backface-visibility: hidden; /* Safari */
                                        backface-visibility: hidden;
                                        cursor: pointer;
                                        position: fixed;
                                    }
                                    width: 170px;
                                    height: 170px;
                                    background-color: transparent;
                                    border-radius: 10px;
                                    position: relative;
                                    perspective: 1000px;
                                    margin: 15px;
                                }
                                .flip-card:hover .flip-card-inner {
                                    transform: rotateY(180deg);
                                }
                                margin: 0px;
                                display: inline-flex;
                                width: auto !important;
                                justify-content: center;
                            }
                        }
                        padding: unset;
                    }
                }
            }
        }
        div#contact-section{
            .container-fluid{
                .card{
                    .card-body{
                        div.row{
                            div {
                                div.card{
                                    div.card-header{
                                        h1{
                                            margin: 0px;
                                        }
                                        color: white;
                                        font-family: fantasy;
                                        border-radius: 0px;
                                        border: 0px;
                                        height: auto;
                                        width: auto;
                                        margin-bottom: 10px;
                                        background: none;
                                    }
                                    div.card-body{
                                        form{
                                            width: 100%;
                                        }
                                        height: auto;
                                        background: none;
                                        border: 0px;
                                        text-align: left;
                                        
                                    }
                                    div.card-footer{
                                        .fanpage{
                                            .btn-group{
                                                padding: 0px;
                                            }
                                        }
                                        height: auto;
                                        padding-left: 0px;
                                        padding-right: 0px;
                                    }
                                    border: thick;
                                    background: none;
                                    height: 100%;
                                }
                                z-index: 4;
                            }
                            div.loadingInit-container{
                                .container-fluid{
                                    .row{
                                        place-content: center;
                                    }
                                }
                            }
                            
                            margin: 0px !important;
                            height: auto;
                        }
                        padding: 0px;
                        background:
                            linear-gradient(
                                rgba(0, 0, 0, 0.4),
                                rgba(0, 0, 0, 0.4)
                            ),
                            url("./assets/img/mountains.png") no-repeat center center fixed;
                        background-size: cover;
                        display: flex;
                        align-items: center;
                    }
                    height: 100vh;
                }
            }
        }
        
        @media screen and (max-width:1200px){
            .inner-container{
                padding: 80px !important;
            }
        }
        @media screen and (max-width:1000px){
            .about-section{
                background-size: 100% !important;
            }
            .inner-container{
                width: 100% !important;
            }
        }
        @media screen and (max-width:600px){
            .inner-container{
                padding: 40px !important;
            }
            .about-section{
                margin: 0px !important;
            }
        }
    }
    .App-footer{
        .scroll-to-top-button {
            position: fixed;
            bottom: 30px;
            right: 30px;
            background-color: #007bff;
            color: #fff;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            cursor: pointer;
            transition: opacity 0.3s ease;
            border: none;
        }
          
        .scroll-to-top-button:hover {
            opacity: 0.8;
        }
    }
}
div.container-fluid{
    div.card{
        div.card-header{
            h1{
                margin: 0px;
            }
            color: white;
            font-family: fantasy;
            background-color: #0e1c3b;
            border-radius: 0px;
        }
        div.card-body{
            padding-right: 0px;
        }
        border: thick;
    }
    padding: 0px !important;
}
.img-thumbnail{
    width: auto;
}
#root{
    width: 100vw;
}