@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loadingInit-container {
    .container-fluid{
        .row{
            .loading-spinner {
                width: 50px;
                height: 50px;
                border: 10px solid #f3f3f3; /* Light grey */
                border-top: 10px solid #383636; /* Black */
                border-radius: 50%;
                animation: spinner 1.5s linear infinite;
            }
        }
        text-align: -webkit-center;
        position: relative;
        top: 30%;
    }
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background-color: white;
    position: fixed;
    left: 0px;
    top:0px;
}